import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import ScrollAnimation from 'react-animate-on-scroll';
import Paragraph from "../components/story/paragraph"

// Custom components
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Features from "../components/features/features"
import Podcasts from "../components/podcast/podcasts"
import PodcastFormSection from "../components/podcast/podcastFormSection"
import Wrapper from "../components/layout/wrapper"

// Static files
import Logo from "../images/logo-show.svg"
import Line from "../images/line-sports.svg"
const LineData = (
  [Line, 'top-0', 'right-36', 'scale-y-104', '']
)


export const query = graphql`
query {
  allMdx(filter: {frontmatter: {templateKey: {eq: "podcast"}}}) {
    nodes {
      frontmatter {
        metaDescription
        metaTitle
        ctaHeading
        ctaHeading2
        ctaHeading3
        templateKey
        intro {
          heading
          text
          imageAlt
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        podcasts {
          heading
          subheading
          podcasturls {
            youtube
            spotify
            google
            apple
          }
        }
        features {
          showcase {
            heading
            text
            image {
              publicURL
            }
          }
          share {
            heading
            text
            image {
              publicURL
            }
          }
          learn {
            heading
            text
            image {
              publicURL
            }
          }
        }
      }
    }
  }
}
` 
const IndexPage = ({ data }) => {
  const allData = data?.allMdx?.nodes[0]
  const allFeatures = Object.values(allData?.frontmatter?.features);
  const allIntro = allData?.frontmatter?.intro 
  const allPodcasts = allData?.frontmatter?.podcasts
  const Stories = () => (
    <div className={`flex flex-col items-center xl:items-start justify-between gap-y-20 xl:flex-row`}>
      <div className={`text-center xl:text-left flex-1`}>
          <img 
              src={Logo} 
              className="w-[176.69px] h-[214.9px] mb-16 xl:mb-6 mx-auto" 
              alt={allIntro[0].heading} 
          />
          <Paragraph
              text={allIntro[0].text}
          />
      </div>
      <div className={`flex flex-1 justify-end`}>
      <ScrollAnimation animateIn="bounceInUp" duration={1.5} animateOnce>
          <GatsbyImage
              image={allIntro[0].image.childImageSharp.gatsbyImageData}
              alt={allIntro[0].imageAlt}
              className={`max-w-[820px]`}
          />
      </ScrollAnimation>
      </div>
    </div>
  )
  return (
    <Layout line={LineData} fullWidth>
      <Seo 
        title={allData.frontmatter.metaTitle}
        description={allData?.frontmatter?.metaDescription}
      />
        <Wrapper>
          <div className="flex flex-col gap-y-32 my-16">
            <Stories/>
          </div>
        </Wrapper>
        <iframe allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write" frameBorder="0" height="450" 
            style={{width: '100%', maxWidth: '770px', margin: '0 auto 90px auto', overflow: 'hidden', background: 'transparent', boxShadow: '0 0 0 12px white', borderRadius: '12px'}} 
            sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" 
            src="https://embed.podcasts.apple.com/gb/podcast/the-murabiyoon-show/id1623453769">
          </iframe> 
        <Podcasts 
          podcasts={allPodcasts}
        />
        <Wrapper> 
          <Features 
            features={allFeatures}
            imgClass="w-[149.83px] h-[116.87px]"
          />
          <PodcastFormSection 
            heading={allData.frontmatter.ctaHeading}
            heading2={allData.frontmatter.ctaHeading2}
            heading3={allData.frontmatter.ctaHeading3}
            title="Message from Podcast form"
          />
        </Wrapper>
    </Layout>
  )
}

export default IndexPage
